import React from 'react'
import APIService from '../api/APIService'
import Subtitle from './Subtitle.js'
import Columns from './Columns.js'
import Column from './Column.js'
import MonthSelector from './MonthSelector.js'
import YearSelector from './YearSelector.js'
import Brands from './Brands.js'
import Notification from './Notification.js'
import SubmitButton from './SubmitButton.js'
import FormField from './FormField.js'
import Input from './Input.js'
import Loader from './Loader.js'
import Box from './Box.js'
import Label from './Label.js'

class GenerateNotas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      brands: [],
      brandsLoaded: false,
      brandsLoadingError: false,
      nextNotaID: 1,
      selectedYear: 0,
      selectedMonth: 0,
      selectedBrands: [],
      brandsWithoutRent: [],
      isSubmitted: false,
      submitIsLoading: false,
      submitResponse: false,
      submitError: false
    }

    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleNextNotaIDChange = this.handleNextNotaIDChange.bind(this)
    this.handleBrandCheck = this.handleBrandCheck.bind(this)
    this.handleNoRentCheck = this.handleNoRentCheck.bind(this)
    this.handleSelectAllBrands = this.handleSelectAllBrands.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const currentDate = new Date()
    let currentMonth = currentDate.getMonth() + 1
    let currentYear = currentDate.getFullYear()
    const itsJanuary = currentMonth === 1
    if (itsJanuary) {
      currentYear = currentYear - 1
      currentMonth = 12
    }
    else {
      currentMonth = currentMonth - 1
    }
    this.setState({selectedYear: currentYear, selectedMonth: currentMonth})
    this.loadBrands(currentYear, currentMonth)
  }

  async loadBrands(year, month) {
    const component = this
    component.setState({brandsLoaded: false})
    const response = await APIService.client.get('/brands', {
      params: {year, month},
    })
    if (response.status !== 200) {
      return component.setState({
        brandsLoaded: true,
        brandsLoadingError: response.data ?? 'Merken konden door een onbekende fout niet geladen worden.',
      })
    }

    const {data, meta} = response.data
    component.setState({
      brandsLoaded: true,
      brands:       data,
      nextNotaID:   meta.lastNotaID + 1,
    })
  }

  handleYearChange(year) {
    this.setState({selectedYear: year})
    this.loadBrands(year, this.state.selectedMonth)
  }

  handleMonthChange(month) {
    this.setState({selectedMonth: month})
    this.loadBrands(this.state.selectedYear, month)
  }

  handleNextNotaIDChange(id) {
    this.setState({nextNotaID: parseInt(id)})
  }

  handleBrandCheck(id, checked) {
    let selectedBrands = this.state.selectedBrands
    var brandKey = selectedBrands.indexOf(id)
    const brandIsAlreadySelected = brandKey !== -1
    if(brandIsAlreadySelected) {
	     selectedBrands.splice(brandKey, 1)
    }
    if (checked) selectedBrands.push(id)
    this.setState({selectedBrands: selectedBrands})
  }

  handleNoRentCheck(id, checked) {
    let brandsWithoutRent = this.state.brandsWithoutRent
    var brandKey = brandsWithoutRent.indexOf(id)
    const brandIsAlreadyChecked = brandKey !== -1
    if(brandIsAlreadyChecked) {
	     brandsWithoutRent.splice(brandKey, 1)
    }
    if (checked) brandsWithoutRent.push(id)
    this.setState({brandsWithoutRent: brandsWithoutRent})
  }

  handleSelectAllBrands(checked) {
    const brands = this.state.brands
    const brandIds = brands.map(brand => brand.id)
    if (checked) this.setState({selectedBrands: brandIds})
    else this.setState({selectedBrands: []})
  }

  async handleSubmit(e) {
    this.setState({
      isSubmitted: true,
      submitIsLoading: true,
      submitResponse: false,
      submitError: false
    })

    const {selectedMonth, selectedYear, selectedBrands, brandsWithoutRent, nextNotaID} = this.state
    const response = await APIService.client.post('/notas', {
      month:      selectedMonth,
      year:       selectedYear,
      brands:     selectedBrands,
      noRent:     brandsWithoutRent,
      lastNotaID: nextNotaID - 1
    })
    if (response.status !== 200) {
      this.setState({
        isSubmitted: true,
        submitIsLoading: false,
        submitError: true,
        submitResponse: response.data
      })
    } else {
      this.setState({
        isSubmitted: true,
        submitIsLoading: false,
        submitError: false,
        selectedBrands: [],
        submitResponse: response.data.replace(/(?:\r\n|\r|\n)/g, '<br>')
      })
    }
    this.loadBrands(this.state.selectedYear, this.state.selectedMonth)

    e.preventDefault()
  }

  render() {
    const date = new Date()
    if (this.state.brandsLoaded && !this.state.submitIsLoading) {
      if (this.state.brandsLoadingError === false) {
        return(
          <>
            <Notification danger={this.state.submitError} succes={!this.state.submitError}>{this.state.submitResponse}</Notification>
            <div>
              <Box>
                <Columns>
                  <Column>
                  <Subtitle label="Nota's verzenden" />
                    <FormField>
                      <Label text='Jaar'/>
                      <YearSelector selected={this.state.selectedYear} min="2018" max={date.getFullYear()} onChange={this.handleYearChange} />
                    </FormField>
                    <FormField>
                      <Label text='Maand'/>
                      <MonthSelector selected={this.state.selectedMonth} min="1" max="12" onChange={this.handleMonthChange} />
                    </FormField>
                    <FormField>
                      <Label text='Nummer eerstvolgende nota'/>
                      <Input
                        type='number'
                        value={this.state.nextNotaID}
                        onChange={this.handleNextNotaIDChange}
                      />
                    </FormField>
                  </Column>
                  <Column>
                    <div className="is-pulled-right">
                      <SubmitButton label="Verzenden naar geselecteerde merken" onClick={this.handleSubmit} />
                    </div>
                  </Column>
                </Columns>
              </Box>
              <Brands selected={this.state.selectedBrands} noRent={this.state.brandsWithoutRent} brands={this.state.brands} handleBrandCheck={this.handleBrandCheck} handleSelectAllBrands={this.handleSelectAllBrands} handleNoRentCheck={this.handleNoRentCheck} />
            </div>
          </>
        )
      } else {
        return(
          <Notification danger={true}>
            {this.state.brandsLoadingError}
          </Notification>
        )
      }
    } else {
      if (!this.state.brandsLoaded) {
        return(
          <div>
            <span className="is-pulled-left has-padding-right has-padding-top"><Loader /></span>
            Merken worden geladen
          </div>
        )
      }
      else {
        return(
          <p>
            <span className="is-pulled-left has-padding-right has-padding-top"><Loader /></span>
            Nota's worden gegenereerd. Dit kan meer dan enkele minuten duren, sluit het venster niet.
          </p>
        )
      }
    }
  }
}

export default GenerateNotas
