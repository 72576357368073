import React from 'react'
import APIService from '../api/APIService'
import Button from './Button'

const OAuthButton = (props) => {

  const {provider, connected} = props

  const [loading, setLoading] = React.useState(false)

  const Icon = React.useMemo(() => {
    if (!(provider in icons)) {
      throw new Error(`No icon defined for OAuth Provider "${provider}"`)
    }
    return icons[provider]
  }, [provider])

  const authenticate = React.useCallback(async () => {
    setLoading(true)

    const response = await APIService.client.get(`/auth/oauth/${provider}/url`)
    setLoading(false)
    if (response.status !== 200) {
      console.error(response.data)
    } else {
      const body = response.data
      window.location = body.data.url
    }
  }, [provider])

  function render() {
    return (
      <Button
        className={`OAuthButton OAuthButton--status-${connected ? 'connected' : 'not-connected'}`}
        renderAsFormControl={false}
        onClick={authenticate}
        loading={loading}
        label={<Icon/>}
      />
    )
  }

  return render()

}

export default OAuthButton

const icons = {
  'exact': () => (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="180" height="180" viewBox="0 0 180 180">
      <g transform="translate(-735 -1242)">
        <rect width="180" height="180" transform="translate(735 1242)" fill="none" />
        <path fill="#e1141d" d="M270.647,145.239H230.379c-19.226,0-28.159-6.76-30.63-23.76h59.443v.022h20.877v-6.023c.014-.2.026-.4.026-.609v-20.6c0-.207-.012-.409-.026-.609V87.7H274.2q-.376-.033-.752-.033H199.608c2.27-17.612,11.178-24.822,30.767-24.822H290.99v-33.8H226.653c-47.06,0-65.13,23.235-65.13,74.995s18.072,75.005,65.13,75.005h64.87v-33.81H270.647Z" transform="translate(595.929 1227.978)" />
      </g>
    </svg>
  ),
  'lightspeed': () => (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="180" height="180" viewBox="0 0 180 180">
      <g transform="translate(-735 -1242)">
        <rect width="180" height="180" transform="translate(735 1242)" fill="none" />
        <path fill="#e81c1c" d="M60.49.3l8.285,14.426a9.948,9.948,0,0,1,0,9.629L19.389,110.126,42.761,150.6a20.428,20.428,0,0,0,35.469,0L101.6,110.126l-6.141-10.95L68.949,145.132a9.591,9.591,0,0,1-8.285,4.809,9.4,9.4,0,0,1-8.3-4.809L31.984,110.126l45.91-79.641,8.285,14.438a9.925,9.925,0,0,1,0,9.618L54.2,110.126l6.292,10.95L95.3,60.682l22.874,39.652a19.938,19.938,0,0,1,0,19.745l-23.2,40.313C91.486,166.534,80.385,180.3,60.49,180.3s-30.823-13.766-34.473-19.907L2.807,120.08a20,20,0,0,1,0-19.745Z" transform="translate(764.506 1241.7)" />
      </g>
    </svg>
  )
}