import React from 'react';
import Select from './Select.js';

class MonthSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(month) {
   this.props.onChange(month);
  }
  render() {
    const months = [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December"
    ];
    const min = this.props.min;
    const max = this.props.max;
    let options = [];
    for(let i=min; i <= max; i++) {
      const isSelected = Number(this.props.selected) === i;
      options.push({
        key: i,
        value: i,
        label: months[i - 1],
        selected: isSelected
      });
    }
    return (
      <Select options={options} onChange={this.handleChange} />
    )
  }
}

export default MonthSelector;
