import React from 'react';

function FormControl(props) {
  return(
    <div className="control">
      {props.children}
    </div>
  )
}

export default FormControl;
