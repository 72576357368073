import React from 'react';
import FormControl from './FormControl.js';

function Button({type, className = '', label, onClick, danger, primary, success, light, loading, renderAsFormControl = true}) {
  let extraClasses = className
  if (danger) extraClasses += ' is-danger'
  if (primary) extraClasses += ' is-primary'
  if (success) extraClasses += ' is-success'
  if (loading) extraClasses += ' is-loading'
  if (light) extraClasses += ' is-light'

  function render() {
    return renderAsFormControl ? (
      <FormControl>
        {renderButton()}
      </FormControl>
    ) : renderButton()
  }

  function renderButton() {
    return (
      <button type={type ?? 'button'} className={"button " + extraClasses} onClick={onClick}>{label}</button>
    )
  }

  return render()

}

export default Button;
