import React from 'react'
import APIService from '../api/APIService'
import Input from './Input.js'
import Button from './Button.js'
import Checkbox from './Checkbox.js'
import Icon from './Icon.js'
import SentLabel from './SentLabel.js'
import TableCell from './TableCell.js'

class Brand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      dataFromServer: {},
      editing: false
    }
    this.update = this.update.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const data = {
      rent:                 this.props.rent ? parseFloat(this.props.rent ) : 0,
      provision:            this.props.provision ? parseFloat(this.props.provision) : 0,
      weeklyUpdate:         this.props.weeklyUpdate ? true : false,
      elaborateStockReport: this.props.elaborateStockReport ? true : false,
    }
    this.setState({
      dataFromServer: data,
      ...data,
    })
  }

  enableEditMode(name, event = false) {
    this.setState({
      editing: name
    })
    if (event) event.preventDefault()
  }

  handleChange(name, value, event = false) {
    if (!this.state.loading) {
      this.setState({
        loading: name,
        [name]: value
      })
      this.update(name, value)
    }
    if (event) event.preventDefault()
  }

  async update(name, value) {
    this.setState({
      editing: false,
      loading: true
    })

    const {rent, provision, weeklyUpdate, elaborateStockReport} = this.state
    const data = {rent, provision, weeklyUpdate, elaborateStockReport}
    data[name] = value

    const response = await APIService.client.post('/brands', {
      id: this.props.id,
      ...data,
    })
    if (response.status !== 200) {
      console.log(response.data)
      return this.setState({
        loading: false,
        [name]:  this.state.dataFromServer[name]
      })
    }
    this.setState({
      loading: false,
      dataFromServer: {name: value, ...this.state.dataFromServer}
    })
  }

  render() {
    const {rent, provision, weeklyUpdate, elaborateStockReport, loading, editing} = this.state
    const {index, id, name, selected, noRent, sent, onSelect, onNoRentCheck} = this.props
    return(
      <tr>
        <TableCell>
          <span className="has-text-grey-light">{index}</span>
        </TableCell>
        <TableCell>
          <Checkbox checked={selected} name={id} onChange={onSelect} />
        </TableCell>
        <TableCell>
          {name}
        </TableCell>
        <TableCell>
          {editing === "rent" &&
            <Input
              short
              autoFocus
              value={rent}
              onBlur={() => this.handleChange("rent", rent ? parseFloat(rent) : 0)}
              onChange={(value) => this.setState({rent: value})}
            />
          }
          {(editing !== "rent" || loading === "rent") &&
          <Button
            disabled={loading === "rent"}
            loading={loading === "rent"}
            light
            onClick={(event) => this.enableEditMode("rent", event)}
            danger={rent ? false : true}
            label={rent ? '€' + rent : 'Niet ingesteld'} />
          }
        </TableCell>
        <TableCell>
          {editing === "provision" &&
            <Input
              short
              autoFocus
              value={provision}
              onBlur={() => this.handleChange("provision", provision ? parseFloat(provision) : 0)}
              onChange={(value) => this.setState({provision: value})}
            />
          }
          {(editing !== "provision" || loading === "provision") &&
          <Button
            disabled={loading === "provision"}
            loading={loading === "provision"}
            light
            onClick={(event) => this.enableEditMode("provision", event)}
            danger={provision ? false : true}
            label={provision ? provision + "%" : 'Niet ingesteld'} />
          }
        </TableCell>
        <TableCell>
          <Checkbox name={id} checked={noRent} onChange={onNoRentCheck} />
        </TableCell>
        <TableCell>
          {sent &&
            <SentLabel date={sent} />
          }
        </TableCell>
        <TableCell>
            <Button
              light={!weeklyUpdate && loading !== "weeklyUpdate"}
              loading={loading === "weeklyUpdate"}
              onClick={(event) => this.handleChange("weeklyUpdate", !weeklyUpdate, event)}
              success={weeklyUpdate}
              label={<Icon color={(weeklyUpdate ? "white" : "grey-lighter")} name="check-circle" />}
            />
        </TableCell>
        <TableCell>
            <Button
              light={!elaborateStockReport && loading !== "elaborateStockReport"}
              loading={loading === "elaborateStockReport"}
              onClick={(event) => this.handleChange("elaborateStockReport", !elaborateStockReport, event)}
              success={elaborateStockReport}
              label={<Icon color={(elaborateStockReport ? "white" : "grey-lighter")} name="check-circle" />}
            />
        </TableCell>
      </tr>
    )
  }
}

export default Brand
