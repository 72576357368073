const env    = window.location.hostname === 'localhost' ? 'local' : 'live'
const secure = window.location.protocol === 'https:'

const config = {
  env: env,
  urls: {
    api: env === 'local' ? `${secure ? 'https' : 'http'}://api.facturatie.themakerstore.test` : '/api',
  },
}

export default config