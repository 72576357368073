import React from 'react'
import { upperFirst } from 'lodash'
import APIService from '../api/APIService'
import Loader from './Loader'
import Container from './Container'

const OAuthManager = function (props) {

  const {provider, code, state, domainPrefix} = props
  const [loading, setLoading]           = React.useState(false);
  const [done, setDone]                 = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const completeOAuthFlow = React.useCallback(async () => {
    if (loading || done) { return }
    setLoading(true)
    const response = await APIService.client.get(`/auth/oauth/${provider}/callback`, {
      params: {code, state, domainPrefix},
    })
    if (response.status !== 200) {
      setErrorMessage(response.data)
    }
    setDone(true)
    setLoading(false)
  }, [done, code, state, provider, domainPrefix, loading])

  React.useEffect(() => {
    completeOAuthFlow()
  }, [completeOAuthFlow])

  function render() {
    return (
      <div className='OAuthManager'>
        {loading ? <Loader/> : renderResult()}
      </div>
    )
  }

  function renderResult() {
    return (
      <Container className='has-text-centered'>
          <p className='is-size-3'>{errorMessage == null ? '✅' : '⚠️'}</p>
          <p className='mt-2'>
            {`Het koppelen met ${upperFirst(provider)} is ${errorMessage ? 'mislukt.' : 'gelukt!'}`}
          </p>
          {errorMessage != null && <p>Error: <i>{errorMessage}</i></p>}
          <a href='/' className='button is-primary mt-4'>Naar startpagina</a>
      </Container>
    )
  }

  return render()

}

export default OAuthManager