import React from 'react';

function TableCell(props) {
  if (props.heading === true) {
    return (
      <th className="table-cell">
        {props.children}
      </th>
    )
  } else {
    return (
      <td className="table-cell">
        {props.children}
      </td>
    )
  }

}

export default TableCell;
