import React from 'react'
import { upperFirst } from 'lodash'
import { useAuth } from '../api/AuthContext'
import Button from './Button'
import Logo from './Logo'
import APIService from '../api/APIService'
import OAuthButton from './OAuthButton'

const AppHeader = () => {

  const {logOut, isLoggedIn} = useAuth()
  const [info, setInfo]      = React.useState(null)

  React.useEffect(() => {
    if (info == null) { return }
    console.log('INFO LOADED', info)
  }, [info])

  const fetchInfo = React.useCallback(async () => {
    if (!isLoggedIn) { return }
    const response = await APIService.client.get('/info')
    if (response.status === 200) {
      setInfo(response.data.data)
    }
  }, [isLoggedIn])

  React.useEffect(() => {
    fetchInfo()
  }, [fetchInfo])

  function render() {
    return (
      <nav className='navbar has-shadow'>
        {renderLogo()}
        <div className='navbar-menu is-active'>
          <div className='navbar-start'>
            {renderWelcome()}
          </div>
          <div className='navbar-end'>
            {renderOAuthStatus()}
            {renderLogOut()}
          </div>
        </div>
      </nav>
    )
  }

  function renderLogo() {
    return (
      <div className='navbar-brand'>
        <a href='/' className='navbar-item'>
          <Logo />
        </a>
      </div>
    )
  }

  function renderWelcome() {
    if (info == null) { return null }
    return (
      <div className='navbar-item'>
        {`👋 Hallo, ${upperFirst(info.user)}`}
      </div>
    )
  }

  function renderOAuthStatus() {
    if (info == null) { return null }

    return (
      <div className='navbar-item'>
        <div className='buttons'>
          {info.integrations.map(({name, connected}) => (
            <OAuthButton
              key={name}
              provider={name}
              connected={connected}
            />
          ))}
        </div>
      </div>
    )
  }

  function renderLogOut() {
    return (
      <div className='navbar-item'>
        <Button renderAsFormControl={false} onClick={logOut} label='Uitloggen' primary/>
      </div>
    )
  }

  return render()

}

export default AppHeader