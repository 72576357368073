import React from 'react';

function FormField(props) {
  let className = "field ";
  if (props.grouped) className += ' is-grouped';
  return(
    <div className={className}>
      {props.children}
    </div>
  )
}

export default FormField;
