import React from 'react';

function Notification(props) {
  let extraClasses;
  if (props.danger !== false) extraClasses += ' is-danger';
  if (props.succes !== false) extraClasses += ' is-success';
  if (props.children === false) {
    return null;
  }
  else {
    return (
      <div className={"notification " + extraClasses}>
        {props.children}
      </div>
    )
  }
}

export default Notification;
