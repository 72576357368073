import React from 'react';
import Select from './Select.js';

class YearSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(year) {
   this.props.onChange(year);
  }

  render() {
    const min = Number(this.props.min);
    const max = Number(this.props.max);
    let options = [];
    for(let i = min; i <= max; i++) {
      const isSelected = Number(this.props.selected) === i;
      options.push({
        key: i,
        value: i,
        label: i.toString(),
        selected: isSelected
      });
    }
    return (
      <Select options={options} onChange={this.props.onChange}/>
    )
  }
}

export default YearSelector;
