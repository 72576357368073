import React from 'react';
import Table from './Table.js';
import TableCell from './TableCell.js';
import Brand from './Brand.js';
import Checkbox from './Checkbox.js';

class Brands extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBrands: this.props.selected,
    }
  }

  render() {
    let brands = [];
    for(let i = 0; i < this.props.brands.length; i++) {
      const isChecked = this.props.selected.indexOf(this.props.brands[i].id.toString()) > -1;
      const noRent = this.props.noRent.indexOf(this.props.brands[i].id.toString()) > -1;
      const brand = this.props.brands[i];
      brands.push(<Brand
          index={i+1}
          key={brand.id}
          id={brand.id}
          onSelect={this.props.handleBrandCheck}
          onNoRentCheck={this.props.handleNoRentCheck}
          selected={isChecked}
          rent={brand.rent}
          provision={brand.provision}
          noRent={noRent}
          sent={brand.sent}
          name={brand.name}
          weeklyUpdate={brand.weeklyUpdate}
          elaborateStockReport={brand.elaborateStockReport} />
      );
    }
    return(
      <Table fullWidth>
        <thead>
          <tr>
            <TableCell>
            </TableCell>
            <TableCell>
              <Checkbox onChange={(id, checked) => this.props.handleSelectAllBrands(checked)} />
            </TableCell>
            <TableCell heading>
              Merknaam
            </TableCell>
            <TableCell heading>
              Huur
            </TableCell>
            <TableCell heading>
              Provisie
            </TableCell>
            <TableCell heading>
              Huur kwijtsch.
            </TableCell>
            <TableCell heading>
              Verzonden
            </TableCell>
            <TableCell heading>
              Wekelijkse update
            </TableCell>
            <TableCell heading>
              Uitgebreid voorraadoverzicht
            </TableCell>
          </tr>
        </thead>
        <tbody>
          {brands}
      </tbody>
    </Table>
    )
  }
}

export default Brands;
