import React from 'react';
import Icon from './Icon';

function SentLabel({date}) {
  return(
    <span className="tag is-medium is-success">
      <Icon color="white" name="check-circle" />
      <span> {date}</span>
    </span>
  )
}

export default SentLabel;
