import React from 'react';

function Container(props) {
  const {size, children} = props
  let className = ' container'
  if (size != null) { className += ` is-${size}`}
  if (props.className != null) { className += ` ${props.className}`}
  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default Container;
