import React from 'react'
import Notification from './Notification'
import Container from './Container'
import Input from './Input'
import SubmitButton from './SubmitButton'
import FormField from './FormField'
import Logo from './Logo'
import { useAuth } from '../api/AuthContext'

const LoginScreen = () => {

  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [loading, setLoading]   = React.useState(false)
  const [error, setError]       = React.useState(null)

  const {logIn} = useAuth()

  const submit = React.useCallback(async (e) => {
    e.preventDefault()

    if (loading) { return }
    if (!username) {
      return setError('Voer een gebruikersnaam in.')
    }
    if (!password) {
      return setError('Voer een wachtwoor in.')
    }

    setLoading(true)
    const response = await logIn(username, password)
    if (response.status !== 200) {
      setLoading(false)
      setError(response.data)
    }

  }, [loading, username, password, logIn])

  function render() {
    return (
      <div className='LoginScreen'>
        <Container>
          {renderNotification()}
          {renderLogo()}
          {renderForm()}
        </Container>
      </div>
    )
  }

  function renderNotification() {
    if (error == null || loading) { return null }
    return (
      <Notification danger>
        <p className='has-text-centered'>{error}</p>
      </Notification>
    )
  }

  function renderLogo() {
    return (
      <div className='block'>
        <Logo className='LoginScreen__logo' />
      </div>
    )
  }
  function renderForm() {
    return (
      <form className='LoginScreen__form' onSubmit={submit}>
        <FormField>
          <Input value={username} onChange={setUsername} placeholder='Gebruikersnaam' />
        </FormField>
        <FormField>
          <Input type='password' value={password} onChange={setPassword} placeholder='Wachtwoord' />
        </FormField>
        <FormField>
          <SubmitButton loading={loading} label='Inloggen' />
        </FormField>
      </form>
    )
  }

  return render()
}

export default LoginScreen