import React from 'react'
import './css/vendor/bulma.min.css'
import './css/vendor/fontawesome/css/fontawesome-all.min.css'
import './css/App.css'
import Container from './components/Container'
import AppHeader from './components/AppHeader'
import GenerateNotas from './components/GenerateNotas'
import AuthContextProvider, { useAuth } from './api/AuthContext'
import LoginScreen from './components/LoginScreen'
import OAuthManager from './components/OAuthManager'

const App = () => {

  function render() {
    return (
      <AuthContextProvider>
        <AppNavigator/>
      </AuthContextProvider>
    )
  }

  return render()
}

const AppNavigator = () => {

  const {isLoggedIn} = useAuth()
  const params       = new URLSearchParams(window.location.search)
  const domainPrefix = params.get('domain_prefix')

  function render() {
    if (params.get('code')) {
      return (
        <OAuthManager
          provider={domainPrefix ? 'lightspeed' : 'exact'}
          code={params.get('code')}
          state={params.get('state')}
          domainPrefix={domainPrefix}
        />
      )
    }
    if (!isLoggedIn) { return <LoginScreen/> }
    return (
      <>
        <AppHeader/>
          <Container size='fluid' className='mt-5'>
            <GenerateNotas/>
          </Container>
      </>
    )
  }

  return render()
}

export default App;
