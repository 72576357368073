import React from 'react';
import FormControl from './FormControl.js';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    this.props.onChange(target.name, target.checked);
  }

  render() {
    return (
      <FormControl>
        <input checked={this.props.checked} type="checkbox" name={this.props.name} onChange={this.handleChange} />
      </FormControl>
    )
  }
}

export default Checkbox;
