import React from 'react';
import Button from './Button.js';

function SubmitButton(props) {
  const label = (props.label ? props.label : "Verzenden");
  return (
    <Button type="submit" primary {...props} label={label} />
  )
}

export default SubmitButton;
