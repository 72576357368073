import React from 'react';

function Table(props) {
  let className = 'table is-hoverable  field';
  if (props.fullWidth) {
    className += '  is-fullwidth';
  }
  return (
    <table className={className}>
      {props.children}
    </table>
  )
}

export default Table;
