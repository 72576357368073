import axios from 'axios'
import config from '../config'
import Cookies from 'js-cookie'

class ApiService {

  constructor() {
    this.client = axios.create({baseURL: config.urls.api})
    this.extendAuthCookie()

    this.client.interceptors.request.use(request => {
      if (request.method === 'post') {
        request.headers['Content-Type'] = 'multipart/form-data'
      }
      return request
    })

    this.client.interceptors.response.use(response => {
      this.extendAuthCookie()
      return response
    }, error => {
      const {response} = error
      if (response?.status === 401) {
        this.removeAuthCookie()
      }
      return error.response ?? {
        data:       null,
        status:     0,
        statusText: 'Kon geen verbinding maken met API',
        headers:    {},
        config:     error.request?.config ?? {},
        request:    error.request,
      }
    })
  }

  getAuthCookie() {
    return Cookies.get('authToken')
  }

  setAuthCookie(authToken) {
    this.client.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
    Cookies.set('authToken', authToken, {
      expires:   1,
      sameSite: 'strict',
      secure:    true,
    })
  }

  removeAuthCookie() {
    this.client.defaults.headers.common['Authorization'] = undefined
    Cookies.remove('authToken')
  }

  extendAuthCookie() {
    const token = this.getAuthCookie()
    if (token == null) { return }

    this.setAuthCookie(token)
  }
}

const API = new ApiService()

export default API