import React from 'react';
import FormField from './FormField.js';
import FormControl from './FormControl.js';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
   this.props.onChange(event.target.value);
  }

  render() {
    const {options} = this.props
    const value     = options.find(it => it.selected)?.value

    return (
      <FormField>
        <FormControl>
          <div className="select">
            <select name={this.props.name} value={value} onChange={this.handleChange}>
              {options.map(option => (
                <option key={option.key} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </FormControl>
      </FormField>
    )
}
}

export default Select;
