import React from 'react';

function Columns(props) {
  return(
    <div className="columns">
      {props.children}
    </div>
  )
}

export default Columns;
