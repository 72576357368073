import React from 'react'
import Cookies from 'js-cookie'
import APIService from './APIService'

const AuthContext = React.createContext({
  username:   null,
  isLoggedIn: false,
  logIn:      () => void 0,
  logOut:     () => void 0,
})

const AuthContextProvider = ({children}) => {
  const authToken = Cookies.get('authToken')

  const [username, setUsername]     = React.useState(null)
  const [isLoggedIn, setIsLoggedIn] = React.useState(authToken != null)

  const logIn = React.useCallback(async (username, password) => {
    const response = await APIService.client.post('/auth/login', {username, password})
    if (response.status === 200) {
      const data = response.data.data
      setUsername(data.username)
      setIsLoggedIn(true)
      APIService.setAuthCookie(data.authToken)
    }

    return response
  }, [])

  const logOut = React.useCallback(() => {
    setIsLoggedIn(false)
    setUsername(null)
    APIService.removeAuthCookie()
  }, [])

  const context = React.useMemo(() => ({
    username,
    isLoggedIn,
    logIn,
    logOut,
  }), [username, isLoggedIn, logIn, logOut])

  return (
    <AuthContext.Provider value={context} children={children}/>
  )
}

export function useAuth() {
  return React.useContext(AuthContext)
}

export default AuthContextProvider