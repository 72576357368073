import React from 'react';
import FormControl from './FormControl.js';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    this.props.onChange(target.value);
    event.preventDefault();
  }

  render() {
    return (
      <FormControl>
        <input
          id={this.props.id}
          autoFocus={this.props.autoFocus}
          onBlur={this.props.onBlur}
          className={"input " + (this.props.short ? "input--short" : "")}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          value={this.props.value ?? ''}
          type={this.props.type ?? "text"}
          readOnly={this.props.readOnly}
          name={this.props.name}
          onChange={this.handleChange} />
      </FormControl>
    )
  }
}

export default Input;
